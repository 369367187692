/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Basic styling */
body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.login-container {
  display: flex;
  /* width: 100%; */
  width: 1200px;
  height: 400px;
  /* height: 100vh;  */
  max-width: 1200px;
  background-color: #fff;
  border-radius: 1.4rem;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-class {
  width: 75px;
  height: auto;
  margin: 1rem;
}

.left-side {
  flex: 1;
  padding: 40px;
  /* background-color: #007bff; */
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.left-side h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.right-side {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form {
  max-width: 400px;
  width: 100%;
}

.login-form h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

input[type="email"] {
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .login-container {
    display: flex;
    width: 100%;
    max-width: 1200px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .login-container {
    flex-direction: column;
    height: auto;
  }

  .left-side,
  .right-side {
    width: 100%;
    padding: 20px;
  }

  .left-side {
    order: 2;
    /* background-color: #007bff; */
    color: #000;
    padding: 40px;
    text-align: left;
  }

  .right-side {
    order: 1;
    padding: 40px;
  }
}
